<template>
  <data-list
    ref="dataList"
    class="content"
    :url="url"
    query="&sort=-id"
    entity-name="商品信息"
    hide-action
    show-row-num
    :columns="columns"
    :search-key-type="searchKeyType"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="主管">
        <select-book type="user" v-model="form.director" @change="updateList"></select-book>
      </a-form-model-item>

      <!-- <a-form-model-item>
        <a-select
          style="width: 180px"
          placeholder="选择出库类别"
          :options="outboundOptions"
          v-model="form.category"
          allowClear
          @change="updateList"
        >
        </a-select>
      </a-form-model-item> -->

      <a-form-model-item>
        <a-input v-model="form.goods_name" placeholder="商品名称/SKU" allowClear></a-input>
      </a-form-model-item>

      <a-form-model-item>
        <a-select
          style="width: 180px"
          placeholder="选择堂口"
          :options="tkOptions"
          v-model="form.tangkou_id"
          allowClear
          @change="updateList"
        >
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-range-picker
          v-model="form.ex_warehouse_date"
          :placeholder="['出库开始日期', '出库截止日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="updateList"
          class="range-picker"
          allowClear
        />
      </a-form-model-item>

      <!-- <a-form-model-item>
        <a-input v-model="form.num" placeholder="固定资产编号" allowClear></a-input>
      </a-form-model-item> -->

      <a-form-model-item>
        <a-input v-model="form.notes" placeholder="备注" allowClear></a-input>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { outboundOptions } from "../../common/asset/type";
import { dataConvertOptions, stringConvertTimestamp } from "../../common/js/tool";
export default {
  name: "OutboundStatistics",
  data() {
    return {
      url: "/admin/asset-ex-warehouse/statistics",
      searchKeyType: {
        director: 2,
        // category: 2,
        goods_name: 2,
        ex_warehouse_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&start_date=${stringConvertTimestamp(value[0])}&end_date=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        tangkou_id: 2,
        notes: 2,
      },
      columns: [
        { title: "商品SKU", dataIndex: "sku" },
        { title: "商品名称", dataIndex: "name" },
        { title: "单位", dataIndex: "unit_name" },
        { title: "数量", dataIndex: "total_num" },
        { title: "平均价(元)", dataIndex: "average_price" },
        { title: "金额(元)", dataIndex: "total_price" },
      ],
      tkOptions: [],
      outboundOptions,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    // 获取堂口
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "order,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped></style>
